<template>
  <div v-if="isPermission">
    <select-shop :loadingButton="loadingButton" v-on:getData="loadData"></select-shop>
    <CCard class="shadow-sm">
      <CCardBody>
        <div class="row">
          <h2 id="tableTitle" class="col-md-10 col-sm-9 col-8 font-weight-normal">
            {{ $t("tableStatsReport") }}
          </h2>
          <div class="col-md-2 col-sm-3 col-4 text-right text-success">
            <CButton id="tableExportButton" v-on:click="exportTableReport()" block color="info">
              <img src="/img/file-excel.png" alt="Excel Icon" style="width: 16px; height: 16px; margin-right: 8px; vertical-align: middle; position: relative; top: -2px;" />{{ $t('export') }}
            </CButton>
          </div>
        </div>
        <CRow>
          <CCol sm="12" lg="12">
            <show-date></show-date>
            <hr />
            <div style="overflow-x: auto">
              <DataTable
                id="tableDatatable"
                style="white-space: nowrap"
                :items="items()"
                :fields="fields"
                hover
                border
              >
              </DataTable>
            </div>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
  <div v-else>
    <access-data></access-data>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import axios from "@/services/service";
import report from "@/services/report";
import Pagination from "@/containers/Pagination";
import util from "@/util/util";
import DataTable from "@/containers/DataTable";
import permis from "@/util/permission";
import tableData from "@/model/report/table";
import { fetchTableData } from "@/controller/report/tableController";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      isLoading: false,
      loadingButton: false,
      tableData: [],
    };
  },
  computed: {
    ...mapGetters(["shops", "date", "users", "permissions"]),
    isPermission() {
      return permis.findPermissionRead("report", this.$route.path);
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId;
      },
      set(newValue) {
        return this.$store.dispatch("setShop", newValue);
      },
    },
    uid() {
      return `${localStorage.getItem("shopsUid")}`;
    },
    startDate() {
      return moment(String(this.date.start)).format("YYYY-MM-DD");
    },
    endDate() {
      return moment(String(this.date.end)).format("YYYY-MM-DD");
    },
    fields() {
      return [
        {
          key: "date",
          label: this.$i18n.t("date"),
          _classes: "text-left text-dark font-weight-normal",
        },
        {
          key: "tableName",
          label: this.$i18n.t("tableNo"),
          _classes: "text-left text-dark font-weight-normal",
        },
        {
          key: "bill",
          label: this.$i18n.t("billAmount"),
          _classes: "text-left text-dark font-weight-normal",
        },
        {
          key: "numCust",
          label: this.$i18n.t("numCust"),
          _classes: "text-left text-dark font-weight-normal",
        },
        {
          key: "items",
          label: this.$i18n.t("items"),
          _classes: "text-left text-dark font-weight-normal",
        },
        {
          key: "grandTotal",
          label: this.$i18n.t("grandTotal"),
          _classes: "text-right text-dark font-weight-normal",
        },
        // {
        //   key: "avgItems",
        //   label: this.$i18n.t("avgItems"),
        //   _classes: "text-left text-dark font-weight-normal",
        // },
        // {
        //   key: "avgNumCust",
        //   label: this.$i18n.t("avgNumCust"),
        //   _classes: "text-left text-dark font-weight-normal",
        // },
        // {
        //   key: "avgGrandTotal",
        //   label: this.$i18n.t("avgGrandTotal"),
        //   _classes: "text-right text-dark font-weight-normal",
        // },
        {
          key: "avgDuration",
          label: this.$i18n.t("avgDuration"),
          _classes: "text-left text-dark font-weight-normal",
        },
        // {
        //   key: 'duration',
        //   label: this.$i18n.t('quantity'),
        //   _classes: 'text-right text-dark font-weight-normal',
        // },
      ];
    },
  },
  created() {
    this.loadData();
  },
  mounted() {
    this.loadData();
  },
  methods: {
    ...util,
    async loadData() {
      this.isLoading = true;
      try {
        this.tableData = await fetchTableData(this.startDate, this.endDate);
        this.isLoading = false;
        this.loadingButton = true;
      } catch (error) {
        console.error("Error loading data:", error);
        this.isLoading = false;
        this.loadingButton = false;
      }
    },
    items() {
      if (this.tableData.length > 0) {
        return this.tableData.map((data) => ({
          date: moment(data.open).format("DD/MM/YYYY"),
          tableName: data.table.name,
          avgDuration: data.avgDuration.toFixed(0),
          // avgGrandTotal: util.convertCurrency(data.avgGrandTotal),
          // avgItems: Number.isInteger(data.avgItems)
          //   ? data.avgItems
          //   : data.avgItems.toFixed(2),
          // avgNumCust: Math.round(data.avgNumCust),
          duration: data.duration,
          grandTotal: util.convertCurrency(data.grandTotal),
          items: data.items,
          numCust: data.numCust,
          bill: data.bill,
        }));
      } else {
        return [];
      }
    },
    exportTableReport() {
      const uid = `${localStorage.getItem("shopsUid")}`;
      const shopObjectId = this.shopObjectId;
      const startAt = moment(String(this.date.start)).format("YYYY-MM-DD");
      const endAt = moment(String(this.date.end)).format("YYYY-MM-DD");
      const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
      };
      const headers = { shopObjectId: shopObjectId };

      report({
        url: "/api/v1.0/" + uid + "/table/statistics/excel",
        params: params,
        method: "GET",
        headers: headers,
        responseType: "blob", // important
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "รายงานสถิติการใช้โต๊ะ.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
